import React from 'react';
import Layout from '../../components/common/Layout';
import SignUp from '../../components/from/SignUp';
import SEO from '../../components/SEO';

const SignUpPage = () => (
  <Layout>
    <SEO
      title="Teste Portknox 14 Tage kostenlos. - Nextcloud Hosting"
      description="Entdecke Nextcloud und dein neues digitales Zuhause."
    />
    <SignUp />
  </Layout>
);

export default SignUpPage;
