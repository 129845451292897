import React from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputField from 'components/common/InputField';
import Button from 'components/common/Button';
import { navigate } from 'gatsby';
import { Fieldset, ErrorField } from './styles';

const SupportForm = ({ intl, intl: { formatMessage } }) => (
  <div>
    <Formik
      enableReinitialize
      initialValues={{
        email: '',
        cloudname: '',
        success: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .strict()
          .trim(formatMessage({ id: 'invalid_space' }))
          .email(formatMessage({ id: 'invalid_email' }))
          .required(formatMessage({ id: 'required_email' })),
        cloudname: Yup.string()
          .strict()
          .trim(formatMessage({ id: 'invalid_space' }))
          .matches(
            /^[a-z0-9A-Z]+$/,
            formatMessage({ id: 'invalid_alphanum' }),
          )
          .required(formatMessage({ id: 'required_username' })),
      })}
      onSubmit={async (
        { email, cloudname },
        { setSubmitting, setFieldError, setFieldValue },
      ) => {
        try {
          await axios.post(
            `${process.env.BACKEND_API_URL}/@cloud-id-free`,
            {
              cloud_id: cloudname,
            },
            {
              headers: {
                accept: 'application/json',
              },
            },
          );
          const language = intl.locale;
          await axios.post(
            `${process.env.BACKEND_API_URL}/@request-trial-cloud`,
            {
              cloud_id: cloudname,
              email,
              language,
            },
            {
              headers: {
                accept: 'application/json',
              },
            },
          );

          setFieldValue('success', true);
          setSubmitting(false);
          navigate(
            language === 'de'
              ? '/de/signup_success'
              : '/en/signup_success',
          );
        } catch (err) {
          setFieldError('email', err.message);
          setSubmitting(false);
          alert('Cloud name already exists. Please choose another one.');
        }
      }}
    >
      {({ setFieldValue, touched, errors, values: { success } }) => (
        <Form>
          {['email', 'cloudname'].map((item, i) => (
            <Fieldset key={i}>
              <label htmlFor={item}>
                <FormattedMessage id={`input_${item}`} />
              </label>
              <div>
                <InputField
                  as={Field}
                  id={item}
                  component="input"
                  type={item === 'email' ? 'email' : 'text'}
                  name={item}
                  error={touched[item] && errors[item]}
                />
              </div>
              <ErrorMessage component={ErrorField} name={item} />
            </Fieldset>
          ))}
          <Button type="submit">Register</Button>
        </Form>
      )}
    </Formik>
  </div>
);

SupportForm.propTypes = {
  intl: propTypes.object,
};

export default injectIntl(SupportForm);
