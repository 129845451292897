import React from 'react';
import { Flex, Item } from 'react-flex-ready';
import { FormattedMessage } from 'react-intl';
import Container from 'components/common/Container';
import SignUpForm from './SignUpForm';
import { Wrapper, Card } from './styles';

export default () => (
  <Wrapper as={Container}>
    <Flex align="flex-start">
      <Item col={7} colTablet={12} colMobile={12} gap={3}>
        <h1>
          <FormattedMessage id="signup_title" />
        </h1>
        <h2>
          <FormattedMessage id="signup_subtitle" />
        </h2>
        <p>
          <FormattedMessage id="signup_pitch" />
        </p>
        <p>
          <FormattedMessage id="signup_description" />
        </p>
      </Item>
      <Item col={5} colTablet={12} colMobile={12} gap={3}>
        <Card>
          <SignUpForm />
        </Card>
      </Item>
    </Flex>
  </Wrapper>
);
